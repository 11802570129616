import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from 'antd';
import Texty from 'rc-texty';

const { Title } = Typography;

const PageTitle = function ({ title, ...rest }) {
  return (
    <Title
      level={2}
      className='page-title'
      {...rest}
    >
      <Texty
        type='alpha'
        mode='smooth'
      >
        {title}
      </Texty>
    </Title>
  );
};

PageTitle.propTypes = {
  title: PropTypes.string.isRequired,
};

export default PageTitle;
